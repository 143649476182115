$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}
















































.ridestyler-showcase {
    //Landscape breakpoints
    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-showcase-modal-content {
            font-size: 1.4em;
            width: 80%;
            max-height: 85%;
        }
    }
    &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-showcase-modal-content {
            width: 70%;
            max-height: 87%;
        }
    }
    &.ridestyler-showcase-breakpoint-md {
        .ridestyler-showcase-modal-content {
            width: 70%;
            max-height: 80%;
        }
    }
    &.ridestyler-showcase-breakpoint-lg {
        .ridestyler-showcase-modal-content {
            width: 55%;
            max-height: 92%;
        }
    }
    //end landscape
    //Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-showcase-modal-content {
                font-size: 0.8em;
                width: 90%;
                max-height: 93%;
                padding: 1em;
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-showcase-modal-content {
                font-size: 0.8em;
                width: 70%;
                max-height: 80%;
            }
        }
    }
    //end portrait
    //Short breakpoints
    &.ridestyler-showcase-breakpoint-viewport-short {
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-showcase-modal-content {
                width: 80%;
                max-height: 87%;
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-showcase-modal-content {
                width: 70%;
                max-height: 86%;
            }
        }
        &.ridestyler-showcase-breakpoint-md {
            .ridestyler-showcase-modal-content {
                width: 70%;
                max-height: 90%;
            }
        }
    }
    //end short

    .ridestyler-showcase-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100000;
        overflow: hidden;
        text-align: center;
        background: rgba(0, 0, 0, 0.8);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        h3 {
            text-align: left;
            font-size: 1.2em;
            margin: 10px 0px;
        }

        h2 {
            text-align: center;
            margin: 1em 0;
            font-size: 1.2em;
        }
    }

    .ridestyler-showcase-modal-close {
        position: absolute;
        right: 1em;
        top: 1em;
        z-index: 100;
        border: none;
        background: none;
        padding: 0px;
        height: 1.75em;
        width: 1.75em;

        &:focus {
            outline: none;
        }

        svg {
            height: 1.75em;
            width: 1.75em;
        }
    }

    .ridestyler-showcase-modal-content {
        margin: 0 auto;
        position: relative;
        height: auto;
        background: white;
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);
        vertical-align: middle;
        display: block;
        padding: 2em;
    }
}
