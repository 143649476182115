$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}






























































































































































































































































.ridestyler-showcase {
    .ridestyler-showcase-visualizer-content {
        display: -webkit-box; display: -ms-flexbox; display: flex;
        -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row;
        width: 100%; height: 67.1%;
        align-self: flex-end;
    }
    .ridestyler-showcase-visualizer-footer {
        display: -webkit-box; display: -ms-flexbox; display: flex;
        flex-direction: column;
        width: 100%; height: 37.5%;
        position:absolute; bottom:0;
        pointer-events: none;
    }
    .ridestyler-showcase-selections {
        height: auto; width: 20%;
        max-height: 100%;
        overflow: auto;
        margin: 0 5% 0 3%;
        align-self: center;
    }
    .ridestyler-showcase-active-customization-information {
        position: relative;
        height: 13%;
        margin: 0;
        padding: 0.4em 1%;
        span.ridestyler-showcase-label {
            padding: 0 .5em 0.1em;
            vertical-align: bottom;
            color: $secondary-button-color;
            border-radius: 2em;
        }
        button {
            width: auto; height: 100%;
            padding: 0 1.3em;
            background: $secondary-button-color;
            color: white;
            border: none; border-radius: 0.2em;
            font-size: 1.1em;
            pointer-events: auto;
        }
    }
    .ridestyler-showcase-vehicle-customization-tabs {
        position: relative;
        height: 73%; width: 100%;
        background: $secondary-background-color;
        border-top: 2px solid darken($secondary-background-color, 5%);
        pointer-events: auto;
    }
    .ridestyler-showcase-vehicle-customization-tab {
        position: relative;
        width: 100%; height: 100%;
        box-sizing: border-box;
    }
    .ridestyler-showcase-settings-menu {
        display: inline-block;
        vertical-align: middle;
        .ridestyler-showcase-menu-list a {
            background-color: #d4d4d4;
            color: #333333;
            border-radius: 4px;
            padding: 0;
            line-height: 2.5em;
            text-align: center;
        }
        .ridestyler-showcase-top-bar-selected-vehicle-settings {
            border: none;
            background: none;
            padding: 0 1em 0;
            .ridestyler-showcase-icon {
                width: 1.5em; height: 1.5em;
                vertical-align: middle;
            }
        }
        .ridestyler-showcase-popover-content {
            width: 150px;
            margin-left: -75px;
            box-sizing: border-box;
            left: 50%; top: 40px;
        }
    }
    // portrait
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-page-visualize {
            .ridestyler-showcase-visualizer-content {
                flex-direction: column;
                justify-content: flex-start;
                height: 62.1%;
            }
            .ridestyler-showcase-visualizer-footer {
                height: 31.5%;
            }
            .ridestyler-vehicle-control-panel {
                height: 11%; width: 96%;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 0;
            }
            .ridestyler-showcase-selections {
                position: relative;
                width: 96%; height: auto;
                margin: 0; padding: 0;
            }
            .ridestyler-showcase-vehicle-customization-tab {
                height: 73%;
            }
            .ridestyler-showcase-vehicle-customization-tabs {
                height: 100%;
            }
            .ridestyler-showcase-vehicle-custimization-mobile-top {
                height: 27%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 2%;
                .ridestyler-showcase-active-customization-information {
                    width: 29%;
                    height: 2.8em;
                    padding: 0;
                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0 1em;
                    }
                }
            }
        }
        &.ridestyler-showcase-breakpoint-md {
            &.ridestyler-showcase-page-visualize {
                .ridestyler-showcase-visualizer-content {
                    height: 67.1%;
                }
                .ridestyler-showcase-vehicle-customization-tab {
                    height: 67%;
                }
                .ridestyler-showcase-visualizer-footer {
                    height: 28.3%;
                }
            }
        }
    }
    //end portrait
}
