$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}
























































































































































































































































.ridestyler-showcase {
    $padding: 0.5em;
    $buttonsHeight: 4em;

    .ridestyler-showcase-filter-modal {
        &.ridestyler-showcase-modal .ridestyler-showcase-modal-wrapper .ridestyler-showcase-modal-content .ridestyler-showcase-modal-close {
            top: 8px;
            right: 8px;
        }
        .ridestyler-showcase-select-box-title {
            padding: 0;
            position: relative;
            top: 0.5em;
            text-align: center;
        }
        .ridestyler-showcase-select-box-option input:checked + span {
            background-color: $secondary-button-color;
        }
    }

    .ridestyler-showcase-filter-modal-filters {
        position: absolute;
        top: $padding;
        left: $padding;
        right: $padding;
        bottom: $buttonsHeight + $padding;
        overflow: none;
    }

    .ridestyler-showcase-filter-modal-filter {
        display: inline-block;
        width: 33.333%;
        height: 94%;
        text-align: center;
        padding: 0.5em;
        box-sizing: border-box;
        vertical-align: top;

        .ridestyler-showcase-select-box {
            height: 100%;
        }
    }

    .ridestyler-showcase-filter-modal-buttons {
        position: absolute;
        left: $padding;
        right: $padding;
        bottom: $padding;
        text-align: center;
        padding: 0.5em;

        > {
            span, button, a {
                vertical-align: middle;
            }
        }

        .ridestyler-showcase-button-large {
            width: 100%;
            display: block;
        }

        .ridestyler-showcase-label {
            font-size: 1.1em;
            margin: 0.4em 0;

            &-small {
                font-size: 0.9em;
            }
        }
    }

    .ridestyler-showcase-filter-confirmation-modal {
        .ridestyler-showcase-modal-wrapper {
            .ridestyler-showcase-modal-content {
                height: auto !important;
                bottom: auto;
                top: 20%;
                box-sizing: border-box;
            }
        }

        .ridestyler-showcase-modal-footer {
            text-align: right;
        }
    }

    //lg
    @media screen and (min-width: 1201px){
        .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
            height: 59%;
        }
    }


    //md
    @media screen and (min-width: 993px) and (max-width: 1200px){
        .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
            height: 59%;
        }
    }

    //portrait all
    @media screen and (orientation: portrait){
        .ridestyler-showcase-filter-modal-filters {
            height: 80%;
        }
        .ridestyler-showcase-filter-modal-filter {
            width: 100%;
            height: 28%;
            margin-bottom: 30px;

            .ridestyler-showcase-select-box-options {
                width: 100%;
                height: 100%;
                overflow: auto;
                position: relative;
                top: 1.5em;
            }
        }
        .ridestyler-showcase-filter-modal-buttons {
            height: auto;
            text-align: center;
            .ridestyler-showcase-button-large {
                float: none;
                display: block;
                margin: 8px auto;
                width: 100%;
            }
            .ridestyler-showcase-label-small {
                display: block;
                font-size: 1.2em;
            }
            span {
              font-size: 1.2em;
            }
            span:first-child {
                display: block;
                margin: 5px;
            }
        }
    }

    //portrait md
    @media screen and (min-width: 993px) and (max-width: 1200px) and (orientation: portrait){
        .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
            height: 86%;
            width: 90%;
        }
        .ridestyler-showcase-filter-modal-filters {
            height: 88%;
        }
        .ridestyler-showcase-filter-modal-filter {
            height: 29%;
        }
    }

    //portrait sm
    @media screen and (min-width: 668px) and (max-width: 992px) and (orientation: portrait){
        .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
            height: 80%;
            width: 90%;
        }
        .ridestyler-showcase-filter-modal-filters {
            height: 85%;
        }
        .ridestyler-showcase-filter-modal-filter {
            height: 28%;
        }
    }

    //portrait xs
    @media screen and (max-width: 667px) and (orientation: portrait){
        .ridestyler-showcase-filter-modal {
            .ridestyler-showcase-modal-content {
                height: 75%;
            }
        }
        .ridestyler-showcase-filter-modal-filters {
            height: 75%;
        }
        .ridestyler-showcase-filter-modal-filter {
            height: 26%;
        }
    }

    //short sm
    @media screen and (min-width: 668px) and (max-width: 992px) and (orientation: landscape){
        .ridestyler-showcase-filter-modal {
            .ridestyler-showcase-modal-content {
                height: 85%;
                width: 83%;
            }
        }
        .ridestyler-showcase-filter-modal {
            .ridestyler-showcase-button-large {
                height: 28px;
            }
        }
    }

    //short xs
    @media screen and (max-width: 667px) and (orientation: landscape){
        .ridestyler-showcase-filter-modal {
            .ridestyler-showcase-modal-content {
                height: 85%;
                width: 83%;
            }
        }
        .ridestyler-showcase-filter-modal {
            .ridestyler-showcase-button-large {
                height: 28px;
            }
        }
    }
}
