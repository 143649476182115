.ridestyler-showcase {
    &.ridestyler-showcase-breakpoint-xs {
        font-size: 0.4em;
        h1 {
            font-size: 1em;
        }
    }
    &.ridestyler-showcase-breakpoint-sm {
        font-size: 0.5em;
    }
    &.ridestyler-showcase-breakpoint-md {
        font-size: 0.8em;
    }
    &.ridestyler-showcase-breakpoint-lg {
        font-size: 1em;
    }
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs {
            font-size: 0.8em;
        }
        &.ridestyler-showcase-breakpoint-sm {
            font-size: 1em;
        }
        &.ridestyler-showcase-breakpoint-md {
            font-size: 1.2em;
        }
        &.ridestyler-showcase-breakpoint-lg {
            font-size: 1.8em;
        }
    }
    &.ridestyler-showcase-breakpoint-viewport-short {
        &.ridestyler-showcase-breakpoint-md {
            font-size: 0.8em;
        }
        &.ridestyler-showcase-breakpoint-lg {
            font-size: 0.8em;
        }
    }
}