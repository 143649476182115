$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}

















































































































































































































































































































































.ridestyler-showcase {

    &.ridestyler-showcase-breakpoint-portrait.ridestyler-showcase-page-select {
      .ridestyler-showcase-select-box-options {
        height: auto;
        max-height: 42vh;
        margin-bottom: 10px;
      }
    }

    .ridestyler-showcase-select-box {
        position: relative;
        outline: none;
    }

    .ridestyler-showcase-select-box-title {
        margin: 0;
        padding: 0;
        float: left;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.5em;
    }

    .ridestyler-showcase-select-box-options {
        @include var(background-color, secondary-background-color);
        border-radius: 0.5em;
        // padding: 10px 0;
        display: block;
        position: absolute;
        top: 3.25em;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        font-size: 1.2em;
        transition: height 500ms ease-in-out;

        &:empty {
            height: 30px;
        }
    }

    .ridestyler-showcase-select-box-option {
        display: block;
        text-align: center;

        label {
            display: block;
            position: relative;

        }

        span {
            padding: 9px;
            display: block;
            cursor: pointer;
            position: relative;;

            // Hover State
            &:hover {
                @include var(background-color, secondary-active-background-color);

                @media (hover: none) {
                    background-color: transparent;
                }
            }
        }

        input.radio {
          position: absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          overflow: hidden;
          opacity: 0;
          margin: 0;
          height: 100%;
        }

        // Selected State
        input:checked + span {
            @include var(background-color, primary-color);
            color: #fff;
        }

        input:focus + span {
            outline: none;
            @include var(background-color, secondary-active-background-color);
        }
    }
}
