$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}

























































































































































































































































































.ridestyler-showcase {

    .ridestyler-showcase-top-bar .ridestyler-showcase-share-button button[type="submit"] {
        margin: 0;
        width: 80%;
    }

    .ridestyler-showcase-share-button {
        height: 100%; width: 100%;

        .ridestyler-showcase-icon-share {
            color: $secondary-button-color;
        }

        .ridestyler-showcase-share-button-networks {
            button {
                width: auto;
            }
        }

        .ridestyler-showcase-share-button-network {
            padding: 1px 0.5em 0.2em;
            margin: 0px 6%;
            svg {
              vertical-align: middle;
            }
        }

        .ridestyler-showcase-loading-indicator {
            width: 1.3em;
            height: 1.3em;
            padding: 0;
        }

        .ridestyler-showcase-popover-content {
            width: 14em;
            margin-left: -8em;
            right: 0;
            top: 41px;
            text-align: center;

            &:before {
                right: 10px;
            }
        }

        .ridestyler-showcase-loading-indicator-inline {
            margin: 0px;
        }

        img {
            margin: 8px 0;
            max-width: 100%;
        }

        input {
            width: 88%;
            font-size: .9em;
            margin: 0 auto 0.5em;
            display: block;
        }

        button {
            position: relative;
            height: 100%; width: 100%;
            padding: 0 1.5em;
            vertical-align: middle;
            color: white;
            background: $secondary-button-color;
            border-radius: 0;
            border: none;
            .ridestyler-showcase-icon {
                width: 1em;
                height: 1em;
            }

            @include focusable;

            
        }
    }

    .ridestyler-showcase-share-email-form {
        $labelSize: 20%;
        margin-top: 10px;
        label > span {
            display: inline-block;
            width: $labelSize;
        }
        input {
            width: 100% - $labelSize;
        }
    }

    .ridestyler-showcase-share-button-networks {
        white-space: nowrap;
        height: auto;
        margin-bottom: .8em;
        display: flex;
        justify-content: space-evenly;
    }

    .ridestyler-showcase-share-email-header {
        text-align: center;
        position: relative;

        button {
            position: absolute;
            left: 0; bottom: 0;
            border: none;
            background: none;
        }

        .ridestyler-showcase-icon {
            width: 1em; height: 1em;
        }
    }

    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-showcase-share-button .ridestyler-showcase-icon-share {
            color: white;
        }
        .ridestyler-showcase-share-button-networks button {
            background-color: $secondary-button-color;
        }
    }
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs, &.ridestyler-showcase-breakpoint-sm, &.ridestyler-showcase-breakpoint-md {
            .ridestyler-showcase-share-button button {
                background-color: transparent;
                width: auto; height: 100%;
                padding: 0 0.5em;
                svg {
                    width: 1.7em; height: 1.7em;
                    vertical-align: middle;
                }
            }
            .ridestyler-showcase-share-button-networks {
                .ridestyler-showcase-share-button-network {
                    background-color: $secondary-button-color;
                    padding: 0.2em 0.5em;
                    width: auto;
                    height: 100%;
                    svg {
                        width: 1em; height: 1em;
                    }
                }
            }
            .ridestyler-showcase-share-button .ridestyler-showcase-icon-share {
                color: $secondary-button-color;
            }
        }
        .ridestyler-showcase-share-button img {
            margin: 0 auto 3.5px;
        }
    }
}
