.ridestyler-showcase {
    div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        color: currentColor;
        font-family: inherit;
        vertical-align: baseline;
        text-shadow: none;
    }

    div {
        line-height: normal;
    }

    fieldset {
        padding:0;
        border: none;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    button {
        cursor: pointer;
        white-space: nowrap;
        text-decoration: none;
        background-position: 0 -200px;
        text-shadow: none;
        font: inherit
    }

    button:hover {
        box-shadow: none;
    }

    button:active {
        box-shadow: none;
    }

    input, textarea, select, button {
        color: inherit;
        font-size: 1em;
    }

    img {
        user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    input, select {
        background: inherit;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 0.5em 1em;
        margin-bottom: 0.5em;
        width: 100%;
        height: 30px;
    }

    select {
        padding: 0 1em;
    }

    label + input {
        margin-top: 0.9em;
    }

    $dlTermWidth: 100px;
    $dlTermMargin: 10px;

    dt {
        float: left;
        clear: left;
        width: $dlTermWidth;
        font-weight: bold;

        &:after {
            content: ':';
        }
    }

    dd {
        margin: 0 0 0 $dlTermWidth + $dlTermMargin;
        padding: 0 0 0.5em 0;

        &:empty:after {
            content: '\00a0\00a0';
        }
    }
}
