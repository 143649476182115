$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}





























































































































































































































































































































































































.ridestyler-showcase {
    &.ridestyler-showcase-page-select {
        .ridestyler-showcase-top-bar-middle {
            text-align: left;
        }
        .ridestyler-showcase-top-bar h1 {
            font-size: 1em;
            padding-left: 0.7em;
        }
        .ridestyler-showcase-renderer {
            width: 95%;
            margin: 0 auto;
        }

        .ridestyler-showcase-background {
            bottom: -$showcase-background-height;
        }

        .ridestyler-showcase-vehicle-selection-menu, .ridestyler-showcase-vehicle-selections {
            position: absolute;
            width: 40%;

            transition: left 250ms ease-in-out;
        }

        .ridestyler-showcase-vehicle-selection-menu {
            font-size: 1.2em;
            left: 30%; bottom: 10%; top: 2em;

            &:focus {
                background: #f7f7f7;
            }

            .ridestyler-showcase-select-box-title {
                text-align: center;
            }
        }

        .ridestyler-showcase-vehicle-selection-back {
            stroke-width: 4px;
            stroke: white;

            position: absolute;
            left: 50%;
            top: 3.2em;
            cursor: pointer;

            @include focusable;

            border: none;
            background: none;

            z-index: 3;

            .ridestyler-showcase-icon {
                width: 1em;
                height: 1em;
            }
        }

        .ridestyler-showcase-vehicle-selections {
            font-size: 1.2em;
            top: 2em; left: 10%;
            box-sizing: border-box;
            padding-right: 10px;
            text-align: center;

            h2 {
                font-size: 1.5em;
                padding: 0;
                margin: 0;
            }

            a {
                font-size: 1.2em;
                padding: 9px;
                @include var(color, primary-color);
                @include focusable;
                display: block;
                text-decoration: none;
            }
        }

        .ridestyler-showcase-vehicle-selections-wrapper {
            border-radius: 1em;
            padding: 0.5em;
            word-wrap: break-word;
        }

        .ridestyler-showcase-vehicle-selections:only-child {
            left: 30%;
            margin-left: 10px;
        }

        .ridestyler-showcase-vehicle-selections ~ .ridestyler-showcase-vehicle-selection-menu {
            left: 50%;
        }

        .ridestyler-showcase-vehicle-select-steps {
            text-align: center;
            margin: 1.75em 0;
        }

        .ridestyler-showcase-vehicle-select-page {
            position: relative;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: auto;
            height: 93.6%;

            .ridestyler-showcase-product-gallery {
                color: #666;
            }

            .ridestyler-showcase-product-gallery-next, .ridestyler-showcase-product-gallery-prev {
                top: 35%;
            }
        }

        .ridestyler-showcase-icon {
            fill: inherit;
        }

        &.ridestyler-showcase-breakpoint-portrait {
            &.ridestyler-showcase-breakpoint-xs {
                .ridestyler-showcase-paint-swatch {
                    width: 4em;
                    height: 4em;
                }
                .ridestyler-showcase-product-gallery-product {
                    width: 20%;
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-paint-swatch {
                    width: 5em;
                    height: 5em;
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                .ridestyler-showcase-paint-swatch {
                    width: 8em;
                    height: 8em;
                }
            }
            .ridestyler-showcase-vehicle-paint-customizer {
                text-align: center;
                justify-content: center;
                .ridestyler-showcase-viewport {
                    left: 0;
                    max-height: none;
                    margin: 0;
                    width: 100%; height: auto;
                }
                .ridestyler-showcase-vehicle-select-confirm-wrapper {
                    margin: 3em 0;
                }
                .ridestyler-showcase-product-gallery {
                    height: 20%;
                    margin: 0;
                }
                .ridestyler-showcase-product-gallery-product {
                    height: auto;
                    .ridestyler-showcase-product-gallery-product-label {
                        width: 100%;
                        margin: 0 auto 0.6em;
                    }
                    .ridestyler-showcase-product-gallery-product-select {
                        width: 100%;
                    }
                }
            }
            .ridestyler-showcase-vehicle-selections {
                text-align: center;
                padding-right: 0;
                position: static;
                margin: 2em auto 0;
                &:only-child {
                    margin-left: auto;
                }
            }
            .ridestyler-showcase-vehicle-selection-menu {
                position: relative;
                width: auto;
                margin: 0 20px;
                left: 0;
            }

            .ridestyler-showcase-select-box-options {
                top: 0.5em;
                position: relative;
                width: 100%;
            }
            .ridestyler-showcase-vehicle-selection-back {
                left: 10px;
                top: 3em;

                .ridestyler-showcase-icon {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }
}
