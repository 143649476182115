$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}





















































.ridestyler-showcase {
    .ridestyler-showcase-top-bar {
        position: relative;
        width: 100%; height: 6.4%;
        -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
        background-color: #F1F3F4;
        color: #262626;
        display: -webkit-box; display: -ms-flexbox; display: flex;
        z-index: 101;

        .ridestyler-showcase-top-bar-left {
            button {
                .ridestyler-showcase-icon {
                    fill: white;
                }
            }
        }

        %topBarCell {
            display: inline-flex;
            align-items: center;
        }

        &-left {
            @extend %topBarCell;

            button {
                position: relative;
                display: inline-block;
                height: 100%;
                padding: 0 1.5em;
                background: #566273;
                color: white;
                border: none;
                outline: none;
                cursor: pointer;

                svg {
                    height: 0.8em;
                    width: 0.8em;
                    margin-right: 5px;
                }
            }
        }

        &-middle {
            @extend %topBarCell;
            font-size: 1.1em;
            padding: 0 0 0 20px;
            align-items: center;
            margin: 0 auto 0 0;
            button {
              border:none;
              background:none;
              padding:0;
            }
            span, button {
                margin-left: 15px;
                cursor: pointer;
                width: 1.3em;
                height: 1.3em;
                svg {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                    fill: $secondary-button-color;
                }
            }
        }

        &-right {
            @extend %topBarCell;
            white-space: nowrap;
        }
    }
    // Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-top-bar {
            &-left {
                button {
                    background-color: transparent;
                    color: $secondary-button-color;
                    border-right: none;
                    padding: 0 0.7em 0 0.5em;
                }
                svg.ridestyler-showcase-icon {
                    margin-right: 0;
                    fill: $secondary-button-color;
                    height: 1.2em;
                    width: 1.6em;
                    vertical-align: bottom;
                }
            }
            &-middle {
                padding: 0;
                margin: 0 auto 0 0;
                width: 76%;
                div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span {
                    height: 1.5em; width: 1.5em;
                    margin: 0;
                    padding: 0 0.7em;
                }
                svg {
                    height: 1.5em; width: 1.5em;
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                .ridestyler-showcase-top-bar {
                    &-middle {
                        line-height: 5em;
                    }
                    h1 {
                        font-size: 1.6em;
                    }
                    .ridestyler-showcase-top-bar-left button {
                        font-size: 1.4em;
                    }
                    .ridestyler-showcase-share-button button {
                        font-size: 1.4em;
                    }
                }
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-top-bar {
                    &-middle {
                        line-height: 3em;
                        div {
                            width: auto;
                        }
                    }
                }
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-xs {
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
        }
    }
    //end portrait
}
