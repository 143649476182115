@mixin button {
    @include var(background-color, primary-color);
    @include var(border-color, primary-color);
    color: white;

    border-radius: 5px;
    border-style: solid;
    border-width: 2px;

    font-size: 1.1em;
    letter-spacing: 0.05em;

    transition-property: color, background-color, border-color;
    transition-duration: 500ms;

    cursor: pointer;
    padding: 3px 10px;
    outline: none;

    @include focusable;

    &:hover {
        @include var(background-color, primary-color-active);
        @include var(border-color, primary-color-active);
        @include var(color, primary-color-overlaid-text);
    }

    &:disabled {
        @include var(background-color, primary-color-disabled);
        @include var(border-color, primary-color-disabled);
        @include var(color, primary-color-disabled-overlaid-text);
        cursor: not-allowed;
    }

    &:active {
        box-shadow: inset 0 0 10px 0 #333;
    }
}

@mixin button-large {
    padding: 0.6em 1em;
}

.ridestyler-showcase .ridestyler-showcase-button {
    @include button;

    > span {
        vertical-align: top;
    }

    &-large {
        @include button-large;
    }

    &-secondary {
        background-color: #aaa;
        color: #333;
        border-color: #aaa;

        &:disabled {
            background-color: #bbb;
        }
    }

    .ridestyler-showcase-label {
        margin-left: 0.3em;
        font-size: inherit;
    }
}

.ridestyler-showcase .ridestyler-showcase-link-button {
    display: inline-block;
    color: #ea1921;
    color: var(--primary-color, #ea1921);
    background: none;
    margin: 0;
    padding: 0;
    border: 0;
    position: relative;
    cursor: pointer;
    font-size: 1em;
    font-weight: normal;

    @include focusable;

    &:disabled {
        color: lightgray;
        cursor: no-drop;
        &:hover {
            color: lightgray;
        }
    }

    &:hover {
        color: var(--primary-color-active, #bf1118);
    }

    &:active {
        box-shadow: none;
    }
}

.ridestyler-showcase {
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-button-large {
            padding: 0.8em 3em;
        }
    }
}
