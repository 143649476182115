$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}

















































.ridestyler-showcase {
    .ridestyler-vehicle-control-panel {
        display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex;
        -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center;
        justify-content: flex-start; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;
        height: 68%; width: 2%;
        padding: 0 1% 0;
        align-self: center;

        .ridestyler-showcase-icon-zoom-in {
            width: 2em; height: 2em;
            margin: 5px 0 0 0;
            pointer-events: none;
        }

        .ridestyler-showcase-icon-rotate {
            pointer-events: none;
        }

        div, button {
            cursor: pointer;
            border:none;
            background:none;

            svg {
                width: 1.3em; height: 1.3em;
                fill: $secondary-button-color;
                &.rotate-disabled {
                    fill: lighten($secondary-button-color, 30%);
                    cursor: not-allowed;
                }
            }
        }
    }
    // Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-vehicle-control-panel {
            .ridestyler-showcase-icon-zoom-in {
                width: 2.5em; height: 2.5em;
                position: relative;
                margin: 0;
                right: 4px;
            }
            .ridestyler-showcase-icon-rotate {
                width: 1.5em; height: 1.5em;
            }
        }
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-vehicle-control-panel {
                .ridestyler-showcase-icon-zoom-in {
                }
                .ridestyler-showcase-icon-rotate {
                }
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-vehicle-control-panel {
                .ridestyler-showcase-icon-zoom-in {
                }
            }
        }
    }
    // End of portrait
    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-vehicle-control-panel {
            padding: 0.2em 0.7em;
            .ridestyler-showcase-icon-zoom-in {
            }
        }
    }
    &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-vehicle-control-panel {
            .ridestyler-showcase-icon-zoom-in {
            }
        }
    }
}
