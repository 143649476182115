.ridestyler-showcase {
    @include baseFont;

    h1 {
        font-size: 2.1em;

        .secondary-title {
            font-size: 1em;
        }
    }

    p {
        &.bold {
            font-weight: bold;
        }

        &+ul, &+p {
            margin-top: 2em;
        }
    }

    ul {
        list-style: disc outside;
        margin-left: 1.5em;

        li {
            &+li {
                margin-top: 0.4em;
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 0.57em;
    }

    .sr {
        position: absolute;
        height: 1px;
        width: 1px;
        clip: rect(1px 1px 1px 1px); // IE 6 and 7
        clip: rect(1px,1px,1px,1px);
        clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
        -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
        overflow: hidden !important;
    }
    strong {
      font-weight: bold;
    }
    a {
        @include var(color, primary-color);
        outline: none;
    }
}
