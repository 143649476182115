$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}




































































































@keyframes fadeInText {
    from {
        opacity: 0;
        top: 10px;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInWheels {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInButton {
    from {
        opacity: 0;
        top: 47%;
    }
    to {
        opacity: 1;
    }
}

.ridestyler-showcase {
    $featuredWheelImageSize: 100px;
    .ridestyler-showcase-custom-intro {
        font-size: 1.5em;
        display: block;
        margin: 3.5em 0 0;
        padding: 0 1em;
    }
    &.ridestyler-showcase-page-home {
        .ridestyler-showcase-page {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
        }
        .ridestyler-showcase-welcome-message {
            font-size: 1.5em;
            display: block;
            margin: 3.5em 0 0;
            padding: 0 1em;
        }
        .ridestyler-showcase-home-bullets {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .ridestyler-showcase-home-bullet {
                margin: 0 2em;
                text-align: center;
                font-size: 1.5em;
                line-height: 1.2em;
                div {
                    border-radius: 80px;
                    height: 2.3em; width: 2.3em;
                    line-height: 2.3em;
                    font-size: 0.9em;
                    background-color: darken($secondary-background-color, 20%);
                    color: white;
                    margin: 0 auto 0.5em;
                }
                &.active {
                    font-weight: bold;
                    div {
                        background-color: $secondary-button-color;
                    }
                }
            }
        }
        #ridestyler-showcase-select-your-vehicle.ridestyler-showcase-button-large {
            font-size: 1.7em;
            padding: .3em 2.6em;
            opacity: 1;
            -webkit-transition: 500ms ease-in fadeInButton;
            transition: 500ms ease-in fadeInButton;
            -webkit-animation: 500ms ease-in fadeInButton;
            -moz-animation: 500ms ease-in fadeInButton;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-fill-mode: forwards;
            -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
        }   
        #ridestyler-showcase-vehicle-selection-wheel-showcase {
            height: 25%;
            text-align: center;
            display: flex;
            flex-direction: row;
            transition: bottom 500ms;
            animation: 500ms ease-in forwards fadeInWheels;
            position: relative;
            align-items: center;
            justify-content: center;

            .ridestyler-showcase-vehicle-selection-featured-wheel {
                width: auto;
                height: 100%;

                + .ridestyler-showcase-vehicle-selection-featured-wheel {
                    margin-left: -15px;
                }
            }
        }
        .ridestyler-showcase-back-panel {
            background: $secondary-background-color;
            position: absolute;
            height: 29%;
            width: 100%;
            bottom: 0;
            z-index: -1;
            text-align: center;
        }
        .wheel-showcase-no-powered {
            margin: 0 auto 7em;
        }
        //portrait
        &.ridestyler-showcase-breakpoint-portrait {
            &.ridestyler-showcase-page-home {
                .ridestyler-showcase-welcome-message {
                    margin: 2em 0 0 0;
                }
                .ridestyler-showcase-home-bullets {
                    flex-direction: column;
                }
                .ridestyler-showcase-home-bullet {
                    align-items: flex-start;
                    margin: 0 auto 7%;
                    width: 14em;
                    display: inline-flex;
                    line-height: 2em;
                    &:last-child {
                        margin: 0 auto 0;
                    }
                    div {
                        display: inline-block;
                        margin: 0 0.8em;
                    }
                    span {
                        white-space: nowrap;
                    }
                }
                #ridestyler-showcase-vehicle-selection-wheel-showcase {
                    height: 15%;
                }
            }
            &.ridestyler-showcase-breakpoint-xs {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 2em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 4em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 4em;
                    }
                }
            }
        }
        //end portrait
    }
}
