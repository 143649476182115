$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}
































































































































































































































































.ridestyler-showcase {
    .ridestyler-showcase-product-gallery-product > * {
        cursor: pointer;
    }
    .ridestyler-showcase-product-gallery-product {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 3%;
        text-align: center;
        vertical-align: top;
        border: none;
        background: none;
        outline: none;
        &.ridestyler-showcase-product-gallery-tire-product {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            max-width: none;
            text-align: center;
            border: none;
            background: none;
            outline: none;
            padding: 0 2.5%;
        }
        &:focus {
          .ridestyler-showcase-product-gallery-product-select {
              @include var(background-color, primary-color-active);
          }
        }
    }
    .ridestyler-showcase-product-gallery-product-tags {
        height: 8%;
        position: relative;
        .ridestyler-showcase-badge-label {
            z-index: 2;
            top: 3em;
            right: 3em;
            padding: 0.5em 1.7em;
            &::after {
                width: 5.5em;
            }
        }
    }
    .ridestyler-showcase-product-gallery-product-tag {
        display: inline-block;
        padding: 0.1em 0.3em;
        margin: 0 0.2em;
        font-size: 0.75em;
        @include var(background-color, secondary-background-color);
        @include var(color, secondary-text-color);
    }
    .ridestyler-showcase-product-gallery-product-not-visualizable-icon {
        position: absolute;
        top: 50%; left: 50%;
        font-size: 1.3em;
        opacity: 0.5;
        color: white;
        margin-left: -1.1em; margin-top: -2em;
        z-index: 12;
    }
    .ridestyler-showcase-product-gallery-product-wheel-info {
        position: relative;
        height: 26%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ridestyler-showcase-product-gallery-product-wheel-image {
        height: 65% !important;
        margin: 0 auto;
    }
    .ridestyler-showcase-product-gallery-product-tire-image {
        width: auto; height: 70%;
        display: inline-block;
        position: relative;
        margin: 0;
    }
    .ridestyler-showcase-product-gallery-product-tire-info {
        position: absolute;
        bottom: 0; left: 0;
        background: #f1f3f4;
        height: 44%; width: 100%;

        .ridestyler-showcase-product-gallery-product-label-size {
            margin-top: .25rem;
            margin-bottom: .25rem;

            height: auto;
            font-weight: normal;
            font-size: .75em;
            position: relative;
            overflow: hidden;
            color: #868686;
        }

        .ridestyler-showcase-product-gallery-product-label {
            
            bottom: 0;
        }
        .ridestyler-showcase-product-gallery-product-label-secondary {
            top: 0;
        }
        .ridestyler-showcase-product-gallery-product-label-primary {
            overflow: hidden;
            bottom: 2px;
        }
    }
    .ridestyler-showcase-product-gallery-product-tire-divider {
        position: absolute;
        display: inline-block;
        height: 1px;
        width: 70%;
        margin: 0 auto;
        background: darken($secondary-background-color, 20%);
        top: 0; left: 0; right: 0;
    }
    %product-gallery-label {
        color: currentColor;
        overflow: inherit;
        text-overflow: ellipsis;
        font-weight: 500;
    }
    .ridestyler-showcase-product-gallery-product-label {
        @extend %product-gallery-label;

        font-size: 1em;
        position: relative;
        overflow: hidden;
        width: 100%
    }
    .ridestyler-showcase-product-gallery-product-label-primary {
        @extend %product-gallery-label;

        height: auto;
        font-weight: bold;
        font-size: 1.2em;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .ridestyler-showcase-product-gallery-lowstock {
        position: absolute;
        top:10px;
        right:10px;
        color:#ffd700;
        font-size:2em;
        line-height: 1;
        &.outofstock {
            color:red;
        }

        .tooltiptext {
            visibility: hidden;
          
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px;
            border-radius: 6px;
            font-size:.5em;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            transform:translateX(-50%);
        }

        &:hover .tooltiptext {
            visibility: visible;
        }
    }

    .ridestyler-showcase-product-gallery-product-label-secondary {
        @extend %product-gallery-label;

        height: auto;
        font-weight: bold;
        font-size: .75em;
        position: relative;
        overflow: hidden;
    }
    .ridestyler-showcase-product-gallery-product-select {
        display: inline-block;
        position: absolute;
        height: 2%; width: 77%;
        background-color: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1234;
        &.selected {
            @include var(background-color, primary-color);
        }
    }
    .ridestyler-showcase-product-gallery-product-loading:after {
        @include crispImageRendering;
        @include rotate;

        background-image: url('../assets/images/tire-loading.png');
        background-size: 100%;
        visibility: visible;
    }
}
