$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}



















































































































































































































































































































































































































































































































































































































































.ridestyler-showcase {
    .ridestyler-showcase-product-gallery {
        color: black;
        height: 100%;
        padding: 0 2.6%;
        outline: none;
        position: absolute;
        left: 0; right: 0;
        .ridestyler-showcase-loading-indicator {
            opacity: 0.75;
        }
    }
    .ridestyler-showcase-product-gallery-wrapper {
        width: 100%; height: 100%;
        white-space: nowrap;
        overflow: hidden;
        &::before {
            opacity: 0;
            content: "";
            background: transparent;
            width: 7%; height: 100%;
            left: 2.6%; top: 0;
            display: block;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            -webkit-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
        }
        &::after {
            opacity: 0;
            content: "";
            background: transparent;
            width: 7%; height: 100%;
            right: 2.6%; top: 0;
            display: block;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            -webkit-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
        }
        &.fade-before {
            &::before {
                opacity: 1;
                content: "";
                background: linear-gradient(to left, transparent, $secondary-background-color);
                width: 7%; height: 100%;
                left: 2.6%; top: 0;
                display: block;
                position: absolute;
                z-index: 1;
                pointer-events: none;
            }
        }
        &.fade-after {
            &::after {
                opacity: 1;
                content: "";
                background: linear-gradient(to right, transparent, $secondary-background-color);
                width: 7%; height: 100%;
                right: 2.6%; top: 0;
                display: block;
                position: absolute;
                z-index: 1;
                pointer-events: none;
            }
        }
    }
    .ridestyler-showcase-product-gallery-no-results {
        font-size: 3em;
        font-weight: 600;
        opacity: 0.5;
        text-align: center;
        padding: 1em 0;
        p {
            font-size: 0.5em;
        }
    }
    %pagination-button {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        padding: 0.9em 0em;
        border: none;
        background: #566273;
        .ridestyler-showcase-icon {
            fill: currentColor;
            opacity: 1;
        }
        &:disabled .ridestyler-showcase-icon {
            opacity: 0.5;
        }
    }
    .ridestyler-showcase-product-gallery-next {
        @extend %pagination-button;
        right: 0;
        vertical-align: middle;
        color: white;
        display: flex;
        svg {
            height: 1em; width: 2em;
        }
    }
    .ridestyler-showcase-product-gallery-prev {
        @extend %pagination-button;
        left: 0;
        vertical-align: middle;
        color: white;
        display: flex;
        svg {
            height: 1em; width: 2em;
        }
    }
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-product-gallery {
            padding: 0;
        }
        .ridestyler-showcase-product-gallery-wrapper {
            &.fade-after::after {
                right: 0;
            }
            &.fade-before::before {
                left: 0;
            }
        }
        .ridestyler-showcase-product-gallery-next, .ridestyler-showcase-product-gallery-prev {
            display: none;
        }
    }
    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        .ridestyler-showcase-product-gallery-wrapper {
            &.fade-before {
                &::before {
                    opacity: 0;
                    content: "";
                    background: transparent;
                }
            }
            &.fade-after {
                &::after {
                    opacity: 0;
                    content: "";
                    background: transparent;
                }
            }
        }
    }
}
