.ridestyler-showcase .ridestyler-showcase-label {
    background-color: #fff;
    @include var(color, primary-color);
    border-radius: 0.5em;
    padding: 0.1em 0.5em;
    border: none;
    vertical-align: middle;

    &-small {
        font-size: 0.7em;
    }

    &-primary {
        @include var(background-color, primary-color);
        @include var(color, primary-color-overlaid-text);
    }

    &-secondary {
        background-color: #ccc;
        color: #262626;
    }
}

.ridestyler-showcase .ridestyler-showcase-badge-label {
    // background: $secondary-background-color;
    display: inline;
    padding: 0.5em 1.3em;
    border-radius: 5px;
    font-size: 0.9em; font-weight: 700;
    position: relative;
    color: white;

    &::after {
        content: '';
        width: auto; height: 100%;
        position: absolute;
        margin: 0;
        right: 0; top: 0; bottom: 0;
        padding: 0;
        display: inline-block;
        border-top: 2.4em solid; border-right: 0.8em solid transparent; border-left: none;
        border-radius: 5px;
        z-index: -1;
        @include var(border-top-color, primary-color);
    }

    &.badge-label-right {
        &::after {
            content: '';
            width: auto; height: 100%;
            position: absolute;
            margin: 0;
            left: 0; top: 0; bottom: 0;
            padding: 0;
            display: inline-block;
            border-top: 2.4em solid; border-left: 0.7em solid transparent; border-right: none;
            border-radius: 5px;
            z-index: -1;
            @include var(border-top-color, primary-color);
        }
    }

    &.badge-default {
        color: $secondary-button-color;
        &::after {
            border-top-color: $secondary-background-color;
        }
    }
}
