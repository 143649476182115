$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}












































































































.ridestyler-showcase {
    .ridestyler-showcase-suspension-adjustment {
        color: $secondary-button-color;
        padding: 0 4.5%;
        top: auto;
        font-size: 0.8em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        label:last-of-type {
          left: 3em; top: 0;
          position: relative;
        }

        .vue-slider {
            padding: 1% 0 !important;
            &:first-of-type {
                margin-bottom: 1em;
            }
        }
    }

    .ridestyler-showcase-suspension-adjustment-lock-button {
        background: none;
        border: none;
        position: absolute;
        top: 47%; left: 4%;
        outline: none;
    }

    .suspension-adjustment-slider-rear-disabled {
        opacity: 0.5;
    }
    //Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-showcase-suspension-adjustment {
                label:last-of-type {
                    left: 1.5em;
                }
            }
            .ridestyler-showcase-suspension-adjustment-lock-button {
                left: 5.5%; top: 56%;
                padding: 0;
                svg {
                    height: 1.5em;
                    width: 1.5em;
                }
            }
        }
    }
    //Landscape breakpoints
    &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-showcase-suspension-adjustment label {
            font-size: 1em;
        }
        .ridestyler-showcase-suspension-adjustment-lock-button {
            top: 48%;
            svg {
                height: 2.3em;
                width: 2.3em;
            }
        }
    }
    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-showcase-suspension-adjustment label {
            margin-bottom: 0px;
            font-size: 1.6em;
        }
        .ridestyler-showcase-suspension-adjustment-lock-button {
            svg {
                height: 2.3em;
                width: 2.3em;
            }
        }
    }
}
