.ridestyler-showcase {
    .ridestyler-showcase-scrollable-y {
        overflow-x: none;
        overflow-y: auto;
        
        -webkit-overflow-scrolling: touch;
    }

    .ridestyler-showcase-menu-list {
        list-style-type: none;
        margin-left: 0;

        a {
            display: block;
            text-decoration: none;
            padding: 0.5em;
        }
    }
}