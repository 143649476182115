$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}













































































































































































































































































































































































































































































.ridestyler-showcase {
    .ridestyler-showcase-details-block {
        .ridestyler-details-info {
           
            img {
                height: 2.5em; width: auto;
                position: relative;
            }
            h1 {
                font-weight: 700;
                padding-top: .3em;
                float: right;
                
            }
            .ridestyler-tire-brand-name {
                font-size: 1em;
                margin-top:1rem;
            }
            .ridestyler-wheel-brand-name {
                font-size: 1em;
                
            }
            .ridestyler-wheel-info-name, .ridestyler-tire-model-name {
                font-size: 1.25em;
                line-height: 1;
                font-weight: bold;
                //white-space: nowrap;
                margin-top:0;
            }
            .ridestyler-wheel-info-wrap {
                width: 100%;
                padding: 0;
                white-space: normal;
            }
            a {
                display: inline-block;
                text-decoration: underline;
                cursor: pointer;
            }
            button.ridestyler-showcase-features-specs {
              border:none;
              background:none;
              display: inline-block;
              text-decoration: underline;
              cursor: pointer;
              line-height: 1;
              @include varImportant(color, primary-color);

              padding-left: 0;
              padding-bottom: 1rem;
              &:hover {
                color: var(--primary-color-active, #bf1118);
              }
              &:focus {
                  outline: 2px dotted;
                  outline-offset: 1px;
                  outline-color: #ea1921;
                  outline-color: var(--primary-color, #ea1921);
              }

            }

            .ridestyler-showcase-features-specs {
                .ridestyler-action-button-wrapper {
                    display: inline-block;
                }
                .ridestyler-showcase-action-button {
                    background: none;
                    border: none;
                    font-size: 0.8em;
                }
            }
            div.ridestyler-showcase-details-block-secondary {
                display: inline-block;
                color: $secondary-button-color;
                font-weight: normal;
                padding: 0 1em 0 0;
                font-size:.8rem;
                span {
                    text-decoration: underline;
                    padding-left: 4px;
                    cursor: pointer;
                }
            }
            .ridestyler-showcase-wheel-actions {
                display: block;
                padding: 0 0 10px;
            }
            .ridestyler-showcase-tire-actions {
                .ridestyler-showcase-link-button {
                    padding: 4% 0 0;
                }
            }
            div.ridestyler-showcase-details-block-tertiary {
                padding: 1rem 0 0 0;
                font-weight: 500;
                color: lighten($secondary-button-color, 20%);
                line-height: 1;

                span {
                    text-decoration: underline;
                    cursor:pointer;
                }
                button {
                  background:none;
                  border:none;
                  text-decoration: underline;
                }
            }
            h2 {
                position: relative;
                font-size: 1.4em; font-weight: 700;
                margin-bottom: 0;
                white-space: normal;
            }
        }
        .ridestyler-details-pricing {
           
            border-bottom: 2px solid $secondary-background-color;
            .ridestyler-starting-price {
                display: flex;
                flex-direction: row;
                //justify-content: space-between;
                align-items: flex-end;
                margin: 0 0 1rem 0;
            }
            .ridestyler-price-box {
                display: inline-block;
                margin-left:2em;
                &:first-child {
                    margin-left:0;
                }
                span {
                    font-weight: 700;
                    display: block;
                    font-size: 0.85em;
                }
                .ridestyler-price-currency {
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    top: 3px;
                    font-size: 1.1em;
                }
                .price {
                    font-weight: 700;
                    display: inline-block;
                    margin: 0;
                    font-size:1.2em;
                    line-height: 1;
                    &.smaller {
                        font-size:1em;
                    }
                    sup {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        top: 2px;
                        font-size: .75em;
                    }
                }
               
            }
            .ridestyler-stock {
                font-weight: 700;
                    display: block;
                    font-size: 0.85em;
                    margin-left:1em;
                    line-height: 1.6em;
                 &.outofstock {
                    color:red;
                }
                &.lowstock {
                    color:#ffd700;
                }
            }
            .ridestyler-no-info {
                color: lighten($secondary-button-color, 20%);
                font-style: italic;
            }
            
        }
        .ridestyler-details-global-actions {
            width:100%;
            margin-top:1rem;

            .ridestyler-showcase-action-container {
                display: block;
                width: 100%;
                button {
                    width: 100%;
                    color: white;
                }
            }
        }

        .ridestyler-pacakge-price {
            .ridestyler-price-box {
                position: relative;
                display: inline-block;
                width: 100%;
                span {
                    display: block;
                }
                .price {
                    display: inline-block;
                }
                .ridestyler-showcase-badge-label {
                    position: absolute;
                    right: 0;
                    bottom: 35%;
                }
            }
        }
    }
    //portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-details-block {
            .ridestyler-details-info {
                padding: 0 0 1%;
                img {
                    height: 2.8em;
                }
                .ridestyler-wheel-info-name {
                    width: 90%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.6em;
                }
                .ridestyler-wheel-info-wrap {
                    padding: 0 0 2% 0;
                }
                .ridestyler-showcase-features-specs {
                    padding: 0;
                    font-size: 1.2em;
                }
                .ridestyler-showcase-details-block-tertiary {
                    padding: 1% 0 0 0;
                }
                .ridestyler-showcase-action-container {
                    display: block;
                    &.ridestyler-showcase-tire-actions {
                        padding: 0;
                    }
                    button {
                        font-size: 1.2em;
                        padding: 3% 0 2%;
                    }
                }
                .ridestyler-showcase-details-block-secondary {
                    padding: 3% 0 0 0;
                }
                h2 {
                    font-size: 1.1em;
                    padding: 0;
                }
            }
            .ridestyler-details-pricing {
                padding: 1% 0;
                .ridestyler-pacakge-price {
                    .ridestyler-price-box {
                        width: 100%;
                        justify-content: space-between;
                        padding: 0 0 1%;
                        span {
                            align-self: center;
                        }
                        .price {
                            align-self: center;
                            margin: 0 auto 0 0;
                        }
                        .ridestyler-showcase-badge-label {
                            position: relative;
                            bottom: 0;
                        }
                    }
                }
                .ridestyler-price-box {
                    width: 60%;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    span {
                        align-self: baseline;
                    }
                    .ridestyler-price-currency {
                        font-size: 0.9em;
                        bottom: 5px;
                        align-self: center;
                        margin: 0 0 0 1rem;
                    }
                    .price {
                        bottom: 0;
                    }
                }
            }
            div.ridestyler-showcase-badge-label {
                font-size: 0.85em;
            }
        }
    }
    //end portrait
    //landscape breakpoints
    &.ridestyler-showcase-breakpoint-xs, &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-showcase-details-block {
            .ridestyler-details-info {
                img {
                    top: 0;
                }
            }
            .ridestyler-price-box {
                span {
                    font-size: 0.85em;
                }
                .ridestyler-price-currency {
                    top: 0;
                    font-size: 0.2em;
                }
                .price {
                    font-size: 1.5em;
                    bottom: 2px;
                }
            }
            .ridestyler-showcase-badge-label {
                font-size: 0.8em;
            }
        }
    }
    &.ridestyler-showcase-breakpoint-md {

    }
    //end landscape breakpoints
    //Short breakpoints
    &.ridestyler-showcase-breakpoint-viewport-short {
        &.ridestyler-showcase-breakpoint-lg {

        }
        &.ridestyler-showcase-breakpoint-md {

        }
    }
}
