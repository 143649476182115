@import "reset";
@import "buttons";
@import "labels";
@import "typography";
@import "containers";
@import "transitions";
@import "breakpoints";
@import "sliders";

.ridestyler-showcase {
    overflow: hidden;

    @each $name, $value in $vars {
        --#{$name}: #{$value};
    }

    .ridestyler-showcase-page {
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        overflow: hidden;
        z-index: 0;
        border: 1px solid darken($secondary-background-color, 15%); border-radius: 5px;
    }
}