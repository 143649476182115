$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}










































.ridestyler-showcase {

    .ridestyler-showcase-product-details-container .ridestyler-showcase-modal-wrapper .ridestyler-showcase-modal-content .ridestyler-showcase-modal-close {
        top: 2em;
    }

    .ridestyler-showcase-product-details {
        .ridestyler-showcase-product-details-header {
            position: relative;
            height: auto;
            text-align: left;
            margin-bottom: 35px;

            h1 {
                margin-bottom: 0;
                display: inline-block;
                width: 50%;
            }

            .ridestyler-showcase-product-details-toggle-buttons {
                position: relative;
                right: 0;
                display: inline-block;
                width: 50%;
                vertical-align: bottom;

                button {
                    width: 50%;
                    font-size: 1.2em;
                    height: 44px;
                    padding: 0em;

                    &:first-child {
                    border-radius: 5px 0px 0px 5px;
                    }
                    &:last-child {
                    border-radius: 0px 5px 5px 0px;
                    }
                }

            }

            .third-title {
                font-size: 0.5em;
                color: #868686;
            }
        }
    }

    .ridestyler-showcase-product-details-body {
        width: 100%;
        position: relative;
        display: block;
    }

    .ridestyler-showcase-product-details-tab {
        margin: 19px 0px;
    }

    .ridestyler-showcase-product-image {
        position: relative;
        height: 100%;
        width: 42%;
        display: inline-block;
        vertical-align: top;
    }

    .ridestyler-showcase-product-details-full-table-container {
        border-radius: 5px;
        max-height: 60vh;
        overflow: auto;
    }

    .ridestyler-showcase-table tr th+th {
        border-left: 1px solid rgba(255, 255, 255, .125);
    }

    .ridestyler-showcase-product-details-summary-table-container {
        position: relative;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        text-align: left;
        vertical-align: top;
        border-radius: 5px;
        max-height: 425px;
        display: inline-block;
        margin-left: 8%;
    }

    //lg
    @media screen and (min-width: 1201px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 50%;
            height: 100%;
            max-height: 47vh;
        }
    }

    //md
    @media screen and (min-width: 993px) and (max-width: 1200px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 50%;
            height: 100%;
            max-height: 39vh;
        }
    }

    //sm
    @media screen and (min-width: 668px) and (max-width: 992px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 47%;
            height: 100%;
            max-height: 55vh;
        }
    }

    //xs
    @media screen and (max-width: 667px){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 45%;
                max-height: 59vh;
            }
            .ridestyler-showcase-product-details-header .ridestyler-showcase-product-details-toggle-buttons button {
                height: 35px;
            }
        }
    }

    //portrait all
    @media screen and (orientation: portrait){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 100%;
                height: 51%;
                margin-top: 15px;
                float: none;
                max-height: 26vh;
                margin-left: 0;
            }
            .ridestyler-showcase-product-image {
                height: 100%;
                width: 50%;
            }
            .ridestyler-showcase-product-details-header {
                margin-bottom: 0px;

                h1 {
                    margin-bottom: 15px;
                    display: inline-block;
                    width: auto;
                }
                .ridestyler-showcase-product-details-toggle-buttons {
                    display: block;
                    width: 100%;

                    button {
                        width: 50%;

                        &:first-child {
                            border-radius: 5px 0px 0px 5px;
                        }

                        &:last-child {
                            margin-left: 0px;
                            border-radius: 0px 5px 5px 0px;
                        }
                    }
                }
            }
        }
    }

    //portrait xs
    @media screen and (max-width: 667px) and (orientation: portrait){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 100%;
                max-height: 27vh
            }
        }
    }
}
