$primary-color: #ea1921;
$primary-color-active: darken($primary-color, 10%);
$primary-color-disabled: #fbbbbd;
$primary-color-overlaid-text: #fff;
$primary-color-disabled-overlaid-text: rgba(255, 255, 255, .7);

$secondary-background-color: #f1f3f4;
$secondary-active-background-color: darken($secondary-background-color, 10%);
$secondary-text-color: #444;
$secondary-active-text-color: #444;
$secondary-button-color: #566273;

$vars: (
    primary-color: $primary-color,
    primary-color-active: $primary-color-active,
    primary-color-disabled: $primary-color-disabled,
    primary-color-overlaid-text: $primary-color-overlaid-text,
    primary-color-disabled-overlaid-text: $primary-color-disabled-overlaid-text,

    secondary-background-color: $secondary-background-color,
    secondary-active-background-color: $secondary-active-background-color,
    secondary-text-color: $secondary-text-color,
    secondary-active-text-color: $secondary-active-text-color
);

/**
 * Looks up $property in the $vars map and outputs the correct fallback color
 * Usage: @include var(color, primary-color);
 */
@mixin var($property, $varName, $defaultValue:map-get($vars, $varName)) {
  #{$property}: map-get($vars, $varName);
  #{$property}: var(--#{$varName}, $defaultValue);
}

@mixin varImportant($property, $varName, $defaultValue:map-get($vars, $varName)) {
    #{$property}: map-get($vars, $varName);
    #{$property}: var(--#{$varName}, $defaultValue) !important;
  }

@mixin focusable {
    &:focus {
        outline: 2px dotted;
        outline-offset: 1px;
        @include var(outline-color, primary-color);
    }
}

$screen-padding: 10px;
$showcase-background-height: 60%;

@mixin baseFont {
    font-family: 'Segoe UI', 'Frutiger', 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', 'Arial', sans-serif;
    font-weight: 400;
}

@mixin crispImageRendering {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
}

@mixin hideElement {
    visibility: hidden;
    position: absolute;
    top: -100%;
    left: -100%;
}

// Z-Index
$zLayer1: 1000;
$zLayer2: 2000;
$zLayer3: 3000;
$zLayerModal: 5000;

// Positioning
@mixin positionAbsoluteCenter($width, $height, $offsetLeft: 0, $offsetTop: 0) {
    position: absolute;

    top: 50%;
    left: 50%;

    width: $width;
    height: $height;

    margin-left: -$width/2 + $offsetLeft;
    margin-top: -$height/2 + $offsetTop;
}

@mixin verticallyCenter() {
    margin: 0;
    position: absolute;
    top: 50%;

    // Compatible >= IE 9
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Transitions
@mixin fadeIn($inOpacity:1) {
    opacity: 0;

    transition-property: opacity;
    transition-duration: 0.5s;

    &.in {
        opacity: $inOpacity;
    }
}

$baseFontSize: 2em;

// Animations
@mixin rotate($duration:1s) {
    animation: rotation $duration infinite linear;
}


























































































































































































.ridestyler-showcase {
    .ridestyler-showcase-button-picker {
        background: none;
        border: none;
        padding: 0;
    }
    .ridestyler-showcase-button-picker-title {
        margin: 0;
        padding: 0.5em;
        float: left;
        width: 100%;
        box-sizing: border-box;
    }
    .ridestyler-showcase-button-picker-options {
        clear: both;
        overflow-x: auto;
    }
    .ridestyler-showcase-button-picker-option {
        display: inline-block;
        margin-right: 0.5em;
        outline: none;
        position:relative;

        input {
            position:absolute;
            top:0;
            left:0;
            display:block;
            opacity:0;
        }

        &.radio {
          background:none;
          color: $secondary-button-color;
          border: 0.24em solid $secondary-background-color; border-radius: 0.4em;
          padding: 0.4em 0.9em;
          cursor: pointer;
          font-weight: 700;
          &.checked {
            background-color: #fff;
            cursor:default;
            @include var(border-color, primary-color);

          }
          @include focusable;
          &:focus {
              outline-offset: -6px;
          }
        }

        span {
            color: $secondary-button-color;
            border: 0.24em solid $secondary-background-color; border-radius: 0.4em;
            display: block;
            padding: 0.4em 0.9em;
            cursor: pointer;
            font-weight: 700;
        }

        input:checked + span {
            background-color: #fff;
            @include var(border-color, primary-color);
        }
    }
    .ridestyler-showcase-button-picker-pagination-button {
        display: none;
        width: 100%;
        background: none;
        border: none;
        cursor: pointer;

        .ridestyler-showcase-icon {
            fill: white;
            height: 1.5em;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:focus {
          outline: none;
        }
    }
}
